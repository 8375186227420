<template>
  <div>
    <div class="card-toolbar mb-5">

      <router-link v-if="$can('modules_setting.create')" :to="{name:'module-data-access.create'}" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('module_data_access.new_module_data_access') }}
      </router-link>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('module_data_access.module') }}</label>
              <select id="module" v-model="filters.module" class="custom-select">
                <option v-for="(row_data, index) in modules_list" :key="index" :value="row_data.slug">{{row_data.name}}</option>
              </select>
<!--              <multiselect v-model="module"-->
<!--                           :placeholder="$t('module_data_access.module')"-->
<!--                           label="name"-->
<!--                           track-by="id"-->
<!--                           :options="modules_list"-->
<!--                           :multiple="false"-->
<!--                           :taggable="false"-->
<!--                           :show-labels="false"-->
<!--                           :show-no-options="false"-->
<!--                           :show-no-results="false">-->
<!--              </multiselect>-->
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{$t('module_data_access.from_date')}}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{$t('module_data_access.to_date')}}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="is_active">{{ $t('status') }}</label>
              <select name="" id="is_active" v-model="filters.is_active" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6 mt-10">
              <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i>
                {{ $t('search') }}
              </b-button>
              <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i>
                {{ $t('reset_search') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('modules_setting.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.status)" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
            <b-form-checkbox v-else size="lg" :disabled="true" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
          </template>
<!--          -->
          <template slot="actions" slot-scope="props">
            <v-icon v-if="$can('modules_setting.update')" small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')"
                     @click="editItem(props.row)">mdi-pencil
            </v-icon>

<!--            -->
            <v-icon small v-if="$can('modules_setting.delete')" class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')"
                    @click="deleteItem(props.row)">mdi-delete
            </v-icon>
          </template>
        </v-server-table>
        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-module-data-access",
  data() {
    return {
      mainRoute: 'settings/modules_setting',
      subMainRoute: 'settings/module_setting',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,
      dataList: [],
      filters: {
        module: null,
        from_date: null,
        to_date: null,
        is_active: null,
      },

      status_list: [
        {id: 1, title: this.$t('active')},
        {id: 0, title: this.$t('inactive')},
      ],
      module: null,
      modules_list: [],

      columns: ['module_name', 'created_at', 'status', 'actions'],
    }
  },
  // watch: {
  //   module: function (val) {
  //     if (val) {
  //       this.filters.module = val.id;
  //     } else {
  //       this.filters.module = null;
  //     }
  //   },
  // },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          module_name: that.$t('module_data_access.module_name'),
          created_at: that.$t('module_data_access.created_at'),
          status: that.$t('status'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        },

      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.module_data_access")}]);
    this.getModulesList();
  },
  methods: {
    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.module = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.is_active = null;
      this.module = null;

      this.$refs.table.refresh();
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    editItem(item) {
      this.$router.push({name: 'module-data-access.edit', params: {id: item.id}});
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    getModulesList() {
      ApiService.get(this.mainRouteDependency + "/modules").then((response) => {
        this.modules_list = response.data.data;
      });
    },

    changeStatus(id, status) {
      ApiService.patch(this.subMainRoute + '/change-status/' + id, {
        is_active: (status ? '1' : '0'),
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
  },
};
</script>
